.start-free {

  #tsparticles {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 0; /* if you use -1 you have to set to `"window"` the interactivity.detectsOn property */
}

  #get-in-touch {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .icon {
    position: absolute;
    color: $start-free-icons-color;

    @for $i from 1 through $start-free-icons-count {
      $style: nth($start-free-icons-map, $i);

      &:nth-of-type(#{$i}) {
        top: map-get($style, top) / 100 * 100%;
        left: map-get($style, left) / 100 * 100%;
      }
    }
  }
}
